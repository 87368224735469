<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="ant-modal-title">
        {{l('查看账单明细')}}
      </div>
    </div>
    <!-- <react-table
      ref="listTable"
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :columns="columns"
      :scroll="{ x: 1200 }"
      :hide-row-selection="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :actions-type="actionsType"
      :is-full="false"
      :hide-row-selection="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <!-- 按钮 -->
      <!-- <a-row :gutter="8">
        <a-col :span="3">
        </a-col>
        <a-col :span="3">
  
        <a-col :span="4">
          <a-button
            type="primary"
            @click="getData"
          >
            {{ l("搜索") }}
          </a-button>
          <a-button @click="clearFilterAndRefresh">
            {{ l("重置") }}
          </a-button>
        </a-col>
      </a-row> -->
    </ele-table>
  </a-spin>

</template>


<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import moment from "moment";
import EleTable from "@/components/ele-table";
import salarypayDetail from "./salarypay-detail";
export default {
  mixins: [AppComponentBase, ModalComponentBase],
  components: {
    EleTable,
  },
  data() {
    return {
      spinning: false,
      commonService: null,
      filterText: "",
      // 选择多少项
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      // 表格
      columns: [
        {
          title: this.l("客户名称"),
          dataIndex: "clientName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "clientName" },
          // width: 200,
          // fixed: "left",
        },
        {
          title: this.l("项目名称"),
          dataIndex: "projectName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "projectName" },
          width: 250,
        },
        {
          title: this.l("项目编码"),
          dataIndex: "projectCode",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "projectCode" },
          width: 250,
        },
        {
          title: this.l("账单月份"),
          dataIndex: "billMonth",
          sorter: false,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "billMonth" },
        },
        {
          title: this.l("账单金额"),
          dataIndex: "billAmount",
          sorter: false,
          width: 120,
          align: "left",
          scopedSlots: { customRender: "billAmount" },
          customRender: (text) => {
            return `￥${text ? text : 0}`;
          },
        },
        {
          title: this.l("应发"),
          dataIndex: "payAmount",
          sorter: false,
          width: 120,
          align: "center",
          scopedSlots: { customRender: "payAmount" },
          customRender: (text) => {
            return `￥${text ? text : 0}`;
          },
        },
        {
          title: this.l("实发"),
          dataIndex: "financePayingAmount",
          sorter: false,
          width: 120,
          align: "center",
          scopedSlots: { customRender: "financePayingAmount" },
          customRender: (text) => {
            return `￥${text ? text : 0}`;
          },
        },
        {
          title: this.l("个税"),
          dataIndex: "personalIncomeTax",
          sorter: false,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "personalIncomeTax" },
          customRender: (text) => {
            return `￥${text ? text : 0}`;
          },
        },
        {
          title: this.l("服务费"),
          dataIndex: "serviceFee",
          sorter: false,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "serviceFee" },
          customRender: (text) => {
            return `￥${text ? text : 0}`;
          },
        },
        {
          title: this.l("税金"),
          dataIndex: "tax",
          sorter: false,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "tax" },
          customRender: (text) => {
            return `￥${text}`;
          },
        },
        {
          title: this.l("账单类型"),
          dataIndex: "billTypeStr",
          sorter: false,
          width: 120,
          align: "center",
          scopedSlots: { customRender: "billTypeStr" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          sorter: false,
          align: "center",
          width: 120,
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 }, //sorting {排序项 ASC/DESC}
      // list-table btns显示参数
      actionsType: {},
      searchFormInline: {
        clientId: undefined,
        clientName: "",
        status: undefined,
        signCompanyId: undefined,
        signCompanyName: "",
        projectId: undefined,
        projectName: "",
        billMonth: "",
      },
      orgOptions: [],
      cusOptions: [],
      projectOptions: [],
      statusOptions: [],
 
    };
  },
  created() {
    this.fullData()
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initActionsType();
    this.getData();
  },
  mounted() {},
  methods: {

    async getData() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Receipt/GetReceiptChildListPaged",
          params: {
            receiptId: this.receiptId,
            filterText: this.filterText,
            sorting: this.request.sorting,
            maxResultCount: this.request.maxResultCount,
            skipCount: this.request.skipCount,
          },
        });
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            billTypeStr: item.billType === 0 ? "非垫付帐单" : "垫付帐单",
          };
        });
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
        // console.log(this.totalPages);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      // this.clientId = "";
      // this.status = "";
      // this.signCompanyId = "";
      // this.billMonth = "";
      this.searchFormInline = {
        clientId: undefined,
        clientName: "",
        status: undefined,
        signCompanyId: undefined,
        signCompanyName: "",
        projectId: undefined,
        projectName: "",
        billMonth: "",
      };
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.filterText = "";
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      const res = this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
      return res;
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "seal",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted("Pages.ReceiptList.Details"),
              name: this.l("查看发放明细"),
              icon: "profile",
              fn: (data) => {
                _this._salaryPayDetail(data);
              },
            }
            // {
            //   granted: this.isGranted("Pages.BillPermissions.Update"),
            //   name: this.l("修改"),
            //   icon: "edit",
            //   fn: (data) => {
            //     if (data.status !== 0) {
            //       _this.notify.info(
            //         _this.l(`只有状态为"待客户确认"的账单才能进行修改`)
            //       );
            //       return;
            //     }
            //     _this.createOrEdit(data, "edit");
            //   },
            // },
            // {
            //   granted: this.isGranted("Pages.BillPermissions.Sure"),
            //   name: this.l("确认账单"),
            //   icon: "profile",
            //   fn: (data) => {
            //     _this._billConfirm(data);
            //   },
            // },
            // {
            //   granted: this.isGranted("Pages.BillPermissions.FlowSure"),
            //   name: this.l("流水确认"),
            //   icon: "read",
            //   fn: (data) => {
            //     _this.createClaimantBill(data, 0);
            //   },
            // },
            // {
            //   // granted: this.isGranted("Pages.BillPermissions.FlowSure"),
            //   granted: true,
            //   name: this.l("流水退回"),
            //   icon: "read",
            //   fn: (data) => {
            //     _this.createClaimantBill(data, 1);
            //   },
            // },
            // {
            //   granted: this.isGranted("Pages.BillPermissions.Apply"),
            //   name: this.l("申请开票"),
            //   icon: "form",
            //   fn: (data) => {
            //     _this._addInvoice(data);
            //   },
            // },
            // {
            //   granted: this.isGranted("Pages.BillPermissions.Cancel"),
            //   name: this.l("账单作废"),
            //   icon: "form",
            //   fn: (data) => {
            //     _this._cancelBill(data);
            //   },
            // },
          ],
          // delete: { granted: this.isGranted('Pages.Customer.Delete'), name: this.l("Delete"), fn: (data) => {
          //   _this.deleteItem(data.id)
          // } },
        },
      };
      this.actionsType = obj;
    },
    async _confirmRange() {
      let ids = [];

      this.spinning = true;
      try {
        const res = await this.commonService.post({
          url: "/api/services/app/Bill/ConfirmRange",
          params: { ids: ids },
        });
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },

    createClaimantBill(item, type) {
      ModalHelper.create(
        type == 0 ? ClaimantBill : RejectClaimantBill,
        {
          params: {
            type: type, // 1 流水确认  2流水退回
            organizationUnitId: item ? item.organizationUnitId : null,
            id: item ? item.id : null,
          },
        },
        {
          isChange: true,
          width: "1000px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    _salaryPayDetail(data) {
      ModalHelper.create(salarypayDetail, {billId: data.id}, {
        confirm: (data) => {
        },
        width: "800px",
        isChange: true,
      }).subscribe((res, message) => {
        this.getData();
      });
    },
  },
};
</script>

<style scoped lang="less">

</style>