<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :scroll="{ x: 0 }"
      :columns="columns"
      :actionsType="actionsType"
      :is-full="true"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
      @emitSortData="updateSortData"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :actions-type="actionsType"
      :is-full="true"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="8">
                <a-col :span="4" class="gutter-row">
          <a-input
            :placeholder="l('发票抬头')"
            v-model.trim="SearchDto.filterText"
            allowClear
          />
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-select
            v-model="SearchDto.customerId"
            style="width: 100%"
            show-search
                     :filter-option="filterOption"
            :options="customerOptions"
            placeholder="请选择客户"
            allowClear
          >
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-select
            v-model="SearchDto.orgId"
            style="width: 100%"
            show-search
            :filter-option="filterOption"
            :options="orgs"
            placeholder="签约公司"
            allowClear
          >
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-select
            v-model="SearchDto.Status"
            placeholder="请选择开票状态"
            style="width: 100%"
            allowClear
          >
            <a-select-option
              v-for="item in [
                { label: '未处理', value: 0 },
                { label: '开票', value: 2 },
                { label: '驳回', value: 3 },
                { label: '退票', value: 4 },
                { label: '作废', value: -1 },
              ]"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col class="gutter-row" :span="3">
          <a-month-picker
            v-model="SearchDto.month"
            placeholder="月份"
            valueFormat="YYYY-MM"
            style="width: 100%"
            allowClear
          />
        </a-col>



        <a-col class="gutter-row" :span="4">
          <a-button
            type="primary"
            @click="search"
            v-if="isGranted('Pages.ReceiptList.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
        </a-col>

        <a-col class="gutter-row" :span="4" style="text-align: right">
          <!-- <a-button
            type="primary"
            @click="openForm()"
            v-if="isGranted('Pages.InvoicePermissions.Create')"
          >
            新增
          </a-button> -->
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import EleTable from "@/components/ele-table";
import receiptApi from "../../../shared/service-proxies/receipt/services/receipt-proxies";
import receiptDetail from "./receipt-detail";
import customerApi from "../../../shared/service-proxies/customer/services/customer-proxies";
import orgApi from "../../../shared/service-proxies/org/services/org-proxies";
import { CommonServiceProxy } from "@/shared/common-service";

export default {
  name: "receipt-list",
  mixins: [AppComponentBase],
  components: { EleTable },
  data: function () {
    return {
      spinning: false,
      commonService: null,
      SearchDto: {
        filterText:'',
        month: "",
        Status: undefined,
        customerId: undefined,
        orgId: undefined,
      },
      request: {
        sorting: "",
        maxResultCount: 50,
        skipCount: 0,
      },
      pagerange: [1, 1],
      companies: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      customerOptions: [],
      orgs: [],
      actionsType: {},
      columns: [],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);

    this.initActionsType();
    this.initData();
    this.initColumns();
  },
  methods: {
    initColumns() {
      let _this = this;
      this.columns = [
        {
          title: this.l("开票日期"),
          dataIndex: "receivedData",
          sorter: true,
          // fixed: "left",
          align: "center",
          customRender: function (text, record) {
            let child = _this.$createElement("a-tooltip", {
              domProps: {
                innerHTML: text.split("T")[0],
              },
              attrs: { title: text.split("T")[0] },
            });
            let obj = {
              children: child,
            };
            return obj;
          },
          scopedSlots: { customRender: "receivedData" },
        },
        {
          title: this.l("发票抬头"),
          dataIndex: "receiptTitle",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "receiptTitle" },
        },
        {
          title: this.l("客户名称"),
          dataIndex: "clientName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "clientName" },
        },
        {
          title: this.l("发票金额"),
          dataIndex: "amount",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "amount" },
        },
        {
          title: this.l("邮寄地址"),
          dataIndex: "address",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "address" },
        },
        {
          title: this.l("发票类型"),
          dataIndex: "receiptTypeName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "receiptTypeName" },
        },
        {
          title: this.l("状态"),
          dataIndex: "status",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "status" },
        },
        {
          title: this.l("开票驳回原因"),
          dataIndex: "errorMessage",
          fixed: "right",
          sorter: false,
          width: 160,
          align: "center",
          scopedSlots: { customRender: "billMemo" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          fixed: "right",
          width: 130,
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    /**
     * 初始化需要显示的func btn
     */
    initActionsType() {
      let _that = this;
      let obj = {
        type: "receipt-list-action",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted("Pages.ReceiptList.Details"),
              name: this.l("查看账单明细"),
              icon: "profile",
              fn: (data) => {
                _that._receiptDetail(data);
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    async initData() {
      this.customerOptions = await customerApi.getOptions();
      this.orgs = (await orgApi.getOptions()).map((item) => {
        return {
          value: item.value,
          label: item.text,
        };
      });
    },
    /**
     * 搜索框
     * @param input
     * @param option
     */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    _receiptDetail(data) {
      ModalHelper.create(
        receiptDetail,
        { receiptId: data.id },
        {
          confirm: (data) => {},
          width: "800px",
          isChange: true,
        }
      ).subscribe((res, message) => {
        this.getData();
      });
    },
    search() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 获取数据 */
    async getData() {
      this.spinning = true;
      let opts = {
        ...this.SearchDto,
        Status:
          this.SearchDto.Status !== undefined ? this.SearchDto.Status : "",
        customerId: this.SearchDto.customerId ? this.SearchDto.customerId : "",
        orgId: this.SearchDto.orgId ? this.SearchDto.orgId : "",
        sorting: this.request.sorting,
        maxResultCount: this.request.maxResultCount,
        skipCount: this.request.skipCount,
      };

      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Receipt/GetReceiptListPaged",
          params: opts,
        });
        this.tableData = res.items.map((v) => {
          return { ...v, status: this.returnStatusStr(v.status), receivedData: moment(v.receivedData).format("YYYY-MM-DD") };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
        console.log(error);
      }
    },
    returnStatusStr(type) {
      switch (type) {
        case 0:
          return "未处理";
        // case -1:
        //   return '作废'
        case 2:
          return "开票";
        case 3:
          return "驳回";
        case 4:
          return "退票";
        default:
          return "作废";
      }
    },
    clearFilterAndRefresh() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * pageSize;
      this.getData();
    },
    clearAndGetData() {
      this.SearchDto.month = "";
      this.SearchDto.Status = undefined;
      this.SearchDto.customerId = undefined;
      this.SearchDto.orgId = undefined;
      this.SearchDto.filterText = "";
      this.getData();
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
