<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :columns="columns"
      :scroll="{ x: 1200 }"
      :hideRowSelection="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
      @emitSortData="updateSortData"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :actions-type="actionsType"
      :is-full="false"
      :hide-row-selection="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <!-- <a-row :gutter="8">
        <a-col class="gutter-row" :span="3">
          <a-input
            v-model.trim="SearchDto.FilterText"
            placeholder="收款人/发放批次"
            style="width: 98%"
          ></a-input>
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-month-picker
            v-model="SearchDto.BatchMonth"
            :placeholder="l('SalaryMonth')"
            valueFormat="YYYY-MM"
            style="width: 98%"
          />
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-select v-model="SearchDto.PayStatus" :placeholder="l('PleaseSelectStatus')" style="width: 98%">
            <a-select-option
              v-for="item in SalaryStatus"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-select
            v-model="SearchDto.CustomerId"
            style="width: 98%"
            show-search
            :filter-option="filterOption"
            :placeholder="l('PleaseSelectCustomer')"
          >
            <a-select-option
              v-for="item in Customers"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-select
            v-model="SearchDto.PayCompanyId"
            style="width: 98%"
            show-search
            :filter-option="filterOption"
            placeholder="请选择发放公司"
            allowClear
          >
            <a-select-option
              v-for="item in PayCompanys"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-button
            type="primary"
            @click="searchData"
            v-if="isGranted('Pages.SalaryDetailsPermissions.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" style="text-align: right" :span="4">
          <a-button-group>
            <a-button
              v-if="isGranted('Pages.SalaryDetailsPermissions.Export')"
                @click="exportToExcel()"
              >
              <a-icon type="file-excel" />
              <span>{{ l("Export") }}</span>
            </a-button>
          </a-button-group>
        </a-col>
      </a-row> -->
    </ele-table>
  </a-spin>
</template>
<script>
import { AppComponentBase,ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import EleTable from "@/components/ele-table";
import SalaryDetail from "@/components/salary/salarydetail.vue";
import { CommonServiceProxy } from "@/shared/common-service";
import { fileDownloadService } from "@/shared/utils";

export default {
  name: "settlementModule",
  mixins: [AppComponentBase,ModalComponentBase],
  components: { EleTable },
  data: function () {
    return {
      spinning: false,
      SearchDto: {
        FilterText: "",
        BatchMonth: "",
        PayStatus: undefined,
        CustomerId: undefined,
        PayCompanyId: undefined,
        Sorting: "",
        SkipCount: 0,
        MaxResultCount: 50,
      },
      commonService: null,
      totalItems: 0,
      pageNumber: 1,
      pagerange: [1, 1],
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      SalaryStatus: [
        // { label: this.l("PleaseSelectStatus"), value: -1 },
        { label: "待处理", value: 0 },
        { label: "发放成功", value: 1 },
        { label: "发放失败", value: 2 },
        { label: "已重新发放", value: 3 },
        { label: "已驳回", value: 4 },
        { label: "发放中", value: 5 },
        { label: "已作废", value: 6 },
      ],
      columns: [],
      PayCompanys: [], // { value: "", label: this.l("PlaceSelectPayCompany") }
      Customers: [], // { value: "", label: this.l("PleaseSelectCustomer") }
    };
  },
  methods: {
    /**
     * ������
     * @param input
     * @param option
     */
    initColumns() {
      var vm = this;
      this.columns = [
        {
          title: "发放批次",
          dataIndex: "number",
          align: "center",
          // fixed: "left",
          ellipsis: true,
        },
        {
          title: "收款人",
          dataIndex: "userName",
          align: "center",
          // fixed: "left",
          scopedSlots: { customRender: "userName" },
        },
        {
          title: "银行账号",
          dataIndex: "bankNum",
          align: "center",
          // width: 200,
          ellipsis: true,
        },
        {
          title: "银行名称",
          dataIndex: "bankName",
          align: "center",
          ellipsis: true,
        },
        {
          title: "支付金额",
          dataIndex: "amount",
          align: "center",
          ellipsis: true,
          customRender: (text) => {
            return `￥${text}`;
          },
        },
        {
          title: "身份证号码",
          dataIndex: "idCard",
          align: "center",
          // width: 200,
          ellipsis: true,
        },
        {
          title: "手机号码",
          dataIndex: "phone",
          align: "center",
          // width: 200,
          ellipsis: true,
        },
        {
          title: "签约公司",
          dataIndex: "payCompanyName",
          align: "center",
          // fixed: "left",
          ellipsis: true,
        },
        {
          title: "客户名称",
          dataIndex: "customerName",
          align: "center",
          // width: 240,
          ellipsis: true,
        },
        {
          title: this.l("发放时间"),
          dataIndex: "payTime",
          align: "center",
          sorter: true,
          ellipsis: true,
          customRender: (text) => {
            if (text) {
              return text.replaceAll("T", " ").split(".")[0];
            } else {
              return "-";
            }
          },
        },
        {
          title: "项目名称",
          dataIndex: "projectName",
          align: "center",
          // width: 240,
          ellipsis: true,
        },
        {
          title: this.l("Status"),
          dataIndex: "statusName",
          //   fixed: "right",
          align: "center",
          // customRender: (text) => {
          //   return <a-tag color="#87d068">{{ text }}</a-tag>;
          // },
        },
        {
          title: "信息状态",
          dataIndex: "identicalBankStr",
          align: "center",
          // width: 240,
          ellipsis: true,
          customRender: (text) => {
            if (text != "") {
              return <a-tag color="#f50">已更改信息</a-tag>;
            }
            return "";
          },
        },
      ];
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    searchData(){
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    /**
     * ��ȡ���з��Ź�˾
     * */
    getAllPayCompany() {
      this.spinning = true;
      this.$api
        .request({
          url: this.$apiUrl + "/api/services/app/Organization/GetAllSelectList",
          method: "GET",
        })
        .then((res) => {
          // this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    /**
     * ��ȡ�ͻ�
     * */
    getAllCutomer() {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    /**
     * 导出为excel
     */
    async exportToExcel() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Salary/GetToExcelFileByDetails",
          params: {
            filterText: this.SearchDto.FilterText,
            yearMonth: this.SearchDto.BatchMonth,
            payStatus:
              this.SearchDto.PayStatus === undefined ? null : this.SearchDto.PayStatus,
            customerId:
              this.SearchDto.CustomerId === undefined ? null : this.SearchDto.CustomerId,
            companyId:
              this.SearchDto.PayCompanyId === undefined
                ? null
                : this.SearchDto.PayCompanyId,
            sorting: this.SearchDto.Sorting == "" ? null : this.SearchDto.Sorting,
            skipCount: this.SearchDto.SkipCount,
            maxResultCount: this.SearchDto.MaxResultCount,
          },
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    /**
     * ��ȡ���� */
    getData() {
      if (this.SearchDto.ProjectId == "") {
        return false;
      }
      this.spinning = true;
      let month = this.SearchDto.BatchMonth;
      console.info(month);
      let options = {
        url: this.$apiUrl + "/api/services/app/Receipt/GetSalaryDetailPagedByBillId",
        method: "GET",
        params: {
        billId:this.billId,
          filterText: this.SearchDto.FilterText,
        //   yearMonth: this.SearchDto.BatchMonth,
        //   payStatus:
        //       this.SearchDto.PayStatus === undefined ? null : this.SearchDto.PayStatus,
        //   customerId:
        //     this.SearchDto.CustomerId === undefined ? null : this.SearchDto.CustomerId,
        //   companyId:
        //     this.SearchDto.PayCompanyId === undefined ? null : this.SearchDto.PayCompanyId,
          sorting: this.SearchDto.Sorting == "" ? null : this.SearchDto.Sorting,
          skipCount: this.SearchDto.SkipCount,
          maxResultCount: this.SearchDto.MaxResultCount,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              data.totalCount / this.SearchDto.MaxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
              this.pageNumber * this.SearchDto.MaxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              return {
                ...item,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    clearFilterAndRefresh() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // ��ҳ�¼�
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.SearchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // tableѡ���¼�
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // ��ҳ
    updatePageChange(newV) {
      let { page, pageSize, skipCount } = newV;
      this.onChange(page, skipCount);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * pageSize;
      this.SearchDto.MaxResultCount = pageSize;
      this.getData();
    },
    clearAndGetData() {
      this.SearchDto.FilterText = "";
      this.SearchDto.BatchMonth = "";
      this.SearchDto.PayStatus = undefined;
      this.SearchDto.CustomerId = undefined;
      this.SearchDto.PayCompanyId = undefined;
      this.SearchDto.SkipCount = 0;
      this.pageNumber = 1;
      this.getData();
    },
  },
  created() {
        this.fullData();
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    // this.getAllPayCompany();
    // this.getAllCutomer();
    this.getData();
  },
  mounted() {
    //this.getData();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
